import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  authApi,
  authExternalCompaniesApi,
  authFoundersApi,
  authInvestorsApi,
  authLinkedinApi,
  currentUserApi,
  usersFinishRegistrationApi,
} from 'apis';
import { LocalStorageKeys, META_INITIAL_STATE } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { InvestorSubTypes, InvitedUserStatus, InviteUserType, UserTypes } from 'constants/user';
import { errorNotify, infoNotify, showServerError, successNotify } from 'helpers';
import {
  CompanyDetailsInput,
  ConnectLinkedInInput,
  FinishRegistrationPayload,
  ForgotPasswordInput,
  InviteInput,
  ServerError,
  SetPasswordPayload,
  SignInPayload,
  SignUpInput,
} from 'interfaces';
import {
  getUser,
  setCurrentUser,
  setCurrentUserAllowedTypes,
  setCurrentUserState,
  updateSigningAuthority,
} from 'modules/current-user/action';
import AlreadyExistEmailToast from 'page-components/auth/AlreadyExistEmailToast';
import history from 'services/history';
import { checkIsAdminRoute, setUserAccess } from 'utils';

export const AUTH_SLICE_NAME = 'auth';

export const submitSignUpData = createAction<SignUpInput>('SUBMIT_SIGN_UP_DATA');

export const resetAuthData = createAction('RESET_AUTH_DATA');

export const asyncSignUp = createAsyncThunk(
  `${AUTH_SLICE_NAME}/signUp`,
  async (
    data: Partial<SignUpInput> & { userType: UserTypes; flowParams: Record<string, string | null>; companyId?: number },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const {
        userType,
        flowParams,
        companyName,
        companyNumber,
        city,
        country,
        county,
        postcode,
        addressLine1,
        addressLine2,
        isAuthorisedToSign,
        signatureCountRequired,
        ...userData
      } = data;
      const payload = {
        ...userData,
        ...(userData.type === InvestorSubTypes.ENTITY
          ? {
              companyData: {
                companyNumber: companyNumber?.label,
                companyName: companyName?.label,
                city,
                country,
                county,
                postcode,
                addressLine1,
                addressLine2,
              },
            }
          : {}),
      };

      const redirectionRoute = userType === UserTypes.INVESTOR ? ROUTES.welcome : ROUTES.companyConfirm;

      const response = await (userType === UserTypes.INVESTOR
        ? authInvestorsApi.signUp(payload)
        : authFoundersApi.signUp(payload));

      if (userData.type === InvestorSubTypes.ENTITY && signatureCountRequired) {
        dispatch(updateSigningAuthority({ isAuthorisedToSign, signatureCountRequired, id: response.data.data.id }));
      }

      if (flowParams.flow) {
        localStorage.setItem(LocalStorageKeys.SIGN_UP_FLOW_PARAMS, JSON.stringify({ ...flowParams }));
      }

      localStorage.setItem(LocalStorageKeys.AUTH_ACCESS_TOKEN, response?.data.accessToken);

      if (response?.data?.refreshToken) {
        localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, response?.data?.refreshToken);
      }

      localStorage.setItem(LocalStorageKeys.IS_MULTI_SIGN_IN, '0');

      if (response.data.data.availableTypes) {
        setCurrentUserAllowedTypes(response.data.data.availableTypes);
      }

      history.push(redirectionRoute);

      await dispatch(
        setCurrentUserState({
          data: { ...response.data.data, userType },
          isAuth: false,
          isActionLoading: false,
          myInvites: [],
          isLoading: false,
          entityInvestors: {
            data: null,
            isLoading: false,
            ...META_INITIAL_STATE,
          },
        }),
      );

      return null;
    } catch (e) {
      if ((e as AxiosError<ServerError>)?.response?.status === 422) errorNotify(AlreadyExistEmailToast);
      else showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncInvitedUserSignUp = createAsyncThunk(
  `${AUTH_SLICE_NAME}/invitedUserSignUp`,
  async (
    data: Partial<SignUpInput> & {
      hash: string;
      userType: UserTypes;
      companyId?: number;
      inviteType?: InviteUserType | null;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const {
        userType,
        companyId,
        inviteType,
        companyName,
        companyNumber,
        city,
        country,
        county,
        postcode,
        addressLine1,
        addressLine2,
        ...signUpData
      } = data;
      const isFmUser = userType === UserTypes.FM_USER;
      const isInvestor =
        userType === UserTypes.INVESTOR || userType === (InvestorSubTypes.ENTITY as unknown as UserTypes);

      localStorage.setItem(LocalStorageKeys.IS_MULTI_SIGN_IN, '0');

      const redirectionRoute = userType === UserTypes.INVESTOR || companyId ? ROUTES.welcome : ROUTES.companyConfirm;

      const companyData = {
        companyName: companyName?.label ?? '',
        companyNumber: companyNumber?.label ?? '',
        city,
        country,
        county,
        postcode,
        addressLine1,
        addressLine2,
      };

      const response = await (isInvestor
        ? authApi.signUpInvitedInvestor({
            ...signUpData,
            companyData,
          })
        : isFmUser
        ? authApi.signUpInvitedFmUser({ ...signUpData, companyId })
        : authApi.signUpInvitedFounder(signUpData));

      if (userType === UserTypes.FOUNDER && inviteType === InviteUserType.DUE_DILIGENCE) {
        localStorage.setItem(LocalStorageKeys.USER_TYPE, UserTypes.FOUNDER);
        localStorage.setItem(LocalStorageKeys.IS_FIRST_SIGN_IN, '1');

        setUserAccess({ accessToken: response?.data.accessToken, refreshToken: response?.data?.refreshToken });

        await dispatch(getUser());
        return null;
      }

      if (isFmUser) {
        localStorage.setItem(LocalStorageKeys.USER_TYPE, UserTypes.FM_USER);
        localStorage.setItem(LocalStorageKeys.IS_FIRST_SIGN_IN, '1');

        setUserAccess({ accessToken: response?.data.accessToken, refreshToken: response?.data?.refreshToken });

        await dispatch(getUser());
        return null;
      }

      localStorage.setItem(LocalStorageKeys.AUTH_ACCESS_TOKEN, response?.data.accessToken);
      history.push(redirectionRoute);

      await dispatch(
        setCurrentUserState({
          data: { ...response.data.data, userType },
          isAuth: isFmUser,
          isActionLoading: false,
          myInvites: [],
          isLoading: false,
          entityInvestors: {
            data: null,
            isLoading: false,
            ...META_INITIAL_STATE,
          },
        }),
      );

      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncSignIn = createAsyncThunk(
  `${AUTH_SLICE_NAME}/signIn`,
  async (data: SignInPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await authApi.signIn(data);

      const userType = response.data.data?.userType;

      if (
        (checkIsAdminRoute() && userType === UserTypes.INVESTOR) ||
        (!checkIsAdminRoute() && (userType === UserTypes.ADMIN || userType === UserTypes.FM_USER))
      ) {
        errorNotify('Sorry you can’t login with your credentials in this area');
        return rejectWithValue('Incorrect area');
      }

      setUserAccess({ accessToken: response?.data.accessToken, refreshToken: response?.data?.refreshToken });
      localStorage.setItem(LocalStorageKeys.USER_TYPE, userType);

      setCurrentUserAllowedTypes(response?.data?.availableTypes);
      const isMultiFlow = response?.data?.availableTypes?.isFounder && response?.data?.availableTypes?.isInvestor;

      localStorage.setItem(LocalStorageKeys.IS_MULTI_SIGN_IN, isMultiFlow ? '1' : '0');

      dispatch(setCurrentUser(response?.data.data));

      return userType;
    } catch (e) {
      errorNotify('The email address or password is incorrect');
      return rejectWithValue(e);
    }
  },
);

export const searchCompanies = createAsyncThunk(`${AUTH_SLICE_NAME}/searchCompanies`, async (data: string) => {
  const response = await authExternalCompaniesApi.searchCompanies(data);

  return response.data;
});

export const addCompany = createAsyncThunk(
  `${AUTH_SLICE_NAME}/saveCompany`,
  async ({ data, disabledRedirect }: { data: CompanyDetailsInput; disabledRedirect?: boolean }) => {
    const { companyName, companyNumber, ...rest } = data;

    if (companyName && companyNumber) {
      const response = await authFoundersApi.addCompany({
        ...rest,
        companyNumber: companyNumber?.label,
        companyName: companyName?.label,
      });

      if (!disabledRedirect) {
        history.push(ROUTES.welcome);
        localStorage.setItem(LocalStorageKeys.COMPANY_NAME, response?.data?.data?.companies[0]?.companyName);
        return true;
      }
    }
    return false;
  },
);

export const inviteFounder = createAsyncThunk(
  `${AUTH_SLICE_NAME}/inviteFounder`,
  async (data: InviteInput, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.inviteUser({ ...data, type: UserTypes.FOUNDER });

      successNotify(`The founder ${data.email} was successfully invited`);
      return response?.data?.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const inviteInvestor = createAsyncThunk(
  `${AUTH_SLICE_NAME}/inviteInvestor`,
  async (data: InviteInput, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.inviteUser({ ...data, type: UserTypes.INVESTOR });

      successNotify(`The investor ${data.email} was successfully invited`);
      return response?.data?.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncForgotPassword = createAsyncThunk(
  `${AUTH_SLICE_NAME}/forgotPassword`,
  async (data: ForgotPasswordInput, { rejectWithValue }) => {
    try {
      await authApi.forgotPassword(data);
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncResetPassword = createAsyncThunk(
  `${AUTH_SLICE_NAME}/resetPassword`,
  async (data: SetPasswordPayload, { rejectWithValue }) => {
    try {
      const response = await authApi.resetPassword(data);

      history.push(ROUTES.resetPasswordSuccess);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetInvitedUser = createAsyncThunk(
  `${AUTH_SLICE_NAME}/getInvitedUser`,
  async (hash: string, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await authApi.getInvitedUser(hash);

      if (data.status === InvitedUserStatus.ACCEPTED) {
        history.push(ROUTES.signIn);
        infoNotify('User has been already signed up');

        return null;
      }

      return data;
    } catch (e) {
      showServerError(e);
      history.push(ROUTES.signIn);

      return rejectWithValue(e);
    }
  },
);

export const connectLinkedIn = createAsyncThunk(
  `${AUTH_SLICE_NAME}/connectLinkedIn`,
  async (data: ConnectLinkedInInput, { rejectWithValue }) => {
    try {
      const response = await authLinkedinApi.connectLinkedIn(data);

      history.push(ROUTES.welcome);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetTermsAndConditions = createAsyncThunk(
  `${AUTH_SLICE_NAME}/asyncGetTermsAndConditions`,
  async () => {
    const response = await authApi.getTermsConditions();
    return response.data.data;
  },
);

export const asyncFinishRegistration = createAsyncThunk(
  `${AUTH_SLICE_NAME}/finishRegistration`,
  async (data: FinishRegistrationPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await usersFinishRegistrationApi.approveFinishRegistration(data);

      setUserAccess({ accessToken: response?.data.accessToken, refreshToken: response?.data?.refreshToken });
      localStorage.setItem(LocalStorageKeys.USER_TYPE, response.data.data.userType);

      dispatch(setCurrentUser(response?.data.data));

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncConfirmEmail = createAsyncThunk(
  `${AUTH_SLICE_NAME}/asyncConfirmEmail`,
  async (payload: string, { dispatch }) => {
    const response = await authApi.confirmEmail(payload);
    dispatch(setCurrentUser(response?.data.data));
  },
);

export const resetAuthInvites = createAction('RESET_AUTH_INVITES');
